<template>
  <div class="faceit">
    <loader v-if="isLoading" />

    <template v-else-if="error.common">
      <InfoBox variant="error">
        {{ $t('oauth.faceit_warface_not-found') }}
      </InfoBox>
      <div class="text">
        {{ $t('oauth.faceit_error') }}
      </div>
      <div class="controls">
        <pvp-btn @click="onCancel">
          {{ $t('global.close') }}
        </pvp-btn>
        <pvp-btn variant="secondary" @click="showAuthPopup">
          {{ $t('account.change') }}
        </pvp-btn>
      </div>
    </template>

    <template v-else-if="error.wfAccount">
      <div class="title">
        {{ $t('oauth.faceit_warface_no-account_title') }}
      </div>
      <div class="text">
        {{ $t('oauth.faceit_warface_no-account_text') }}
      </div>
      <div class="controls">
        <pvp-btn href="https://wf.mail.ru">
          {{ $t('oauth.faceit_warface_create') }}
        </pvp-btn>
        <pvp-btn variant="secondary" @click="onCancel">
          {{ $t('global.back') }}
        </pvp-btn>
      </div>
    </template>

    <template v-else>
      <div class="logo">
        <img src="~@assets/img/logo/faceit.png" alt="faceit" />
        <span class="logo-title">FACEIT</span>
      </div>
      <div class="title">
        {{ $t('oauth.faceit_title') }}
      </div>
      <div class="text">
        {{ $t('oauth.faceit_text', { host: window.location.host }) }}
      </div>
      <div class="controls">
        <pvp-btn @click="onConfirm">
          {{ $t('global.confirm') }}
        </pvp-btn>
        <pvp-btn variant="secondary" @click="onCancel">
          {{ $t('global.cancel') }}
        </pvp-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { auth } from '@src/plugins/Auth';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'OauthFaceit',
  components: { InfoBox },
  data: () => ({
    isLoading: false,
    error: {
      common: false,
      wfAccount: false,
    },
  }),
  computed: {
    ...mapGetters('profile', ['isLoggedIn', 'hasMailAccount']),
  },
  created() {
    if (this.isLoggedIn) {
      this.isLoading = true;
      this.checkWfAccount();
    }
  },
  methods: {
    checkWfAccount() {
      api
        .post('/wflogin/nick')
        .then((data) => {
          this.error.wfAccount = data?.have_wf_account === false;
        })
        .finally(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.error.common = true;
        });
    },
    onConfirm() {
      if (this.hasMailAccount) {
        const {
          client_id: clientId,
          return_url: returnUrl,
          state,
        } = this.$route.query;
        return api
          .post('/oauth/access', {
            clientId,
            return_url: returnUrl,
            state,
          })
          .then((data) => {
            if (data.success) {
              window.location.href = data.url;
            } else {
              this.error.common = true;
            }
          })
          .catch(() => {
            this.error.common = true;
          });
      }
      return this.showAuthPopup();
    },
    onCancel() {
      window.location.href =
        this.$route.query.return_url || 'https://faceit.com';
    },
    showAuthPopup() {
      auth.login();
    },
  },
};
</script>

<style lang="scss" scoped>
.faceit {
  color: white;
}

.logo {
  display: flex;
  align-items: center;

  &-title {
    margin-left: 12px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 24px;
}

.text {
  margin-top: 12px;
}

.controls {
  margin-top: 15px;
  @include min-tablet() {
    display: flex;
    justify-content: space-between;
  }

  .button {
    margin-top: 15px;
    @include min-tablet() {
      flex-grow: 1;
      & + .button {
        margin-left: 12px;
      }
    }
    @include max-tablet() {
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="oauth">
    <div class="bg-image"></div>
    <pvp-modal
      class="full-width"
      width="579"
      :value="true"
      :permanent="true"
    >
      <div class="header">
        <div class="wrapper">
          <icon class="logo" name="logo" :inline="false" />
          <div class="user">
            <user-avatar :src="profile.avatarUrl" />
            <span v-if="isLoggedIn" class="nick">
              {{ profile.nick }}
            </span>
          </div>
        </div>
      </div>
      <div class="body">
        <oauth-faceit class="wrapper" />
      </div>
    </pvp-modal>
  </div>
</template>

<script>
import OauthFaceit from '@components/Oauth/Faceit.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  components: {
    Icon,
    OauthFaceit,
  },
  computed: {
    ...mapGetters('profile', ['getCurrentProfile', 'isLoggedIn']),

    profile() {
      return this.getCurrentProfile;
    },
  },
};
</script>

<style lang="scss" scoped>
.oauth {
  height: 100%;
  background: $cyprus no-repeat
    url(~@assets/img/placeholders/oauth-bg.jpg) 50% 0 / auto 887px;
}

.pvp-modal {
  ::v-deep {
    .pvp-modal-title,
    .pvp-modal-body {
      padding: 0;
    }
  }
}

.wrapper {
  max-width: 400px;
  padding: 0 12px;
  margin: 0 auto;
}

.header {
  padding: 16px 0;
  box-shadow: 0 2px 12px rgba(black, 0.3),
    inset 0 -1px 0 rgba(white, 0.1);

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo {
  width: 120px;
  height: 32px;
  color: $azure;
}

.user {
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  align-items: center;

  .user-avatar {
    width: 32px;
    height: 32px;
  }

  .nick {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    margin-left: 8px;
  }
}

.body {
  background-color: $dark;
  padding: 40px 0;
}
</style>
